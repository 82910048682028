<template>
  <a-layout style="min-height: 100vh;max-height: 100vh">
    <app-layout-sider ref="sider"></app-layout-sider>
    <a-layout>
      <a-layout-header class="header">
        <div style="inline">
          <div style="float:left;">
            <a-icon
              style="padding-left: 10px;font-size: 20px;"
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="changeSiderCollapsed"
            />
          </div>
          <div style="float:right;">
            <div class="main-head-layout-r">
              <a-dropdown style="padding: 0 20px">
                <span>{{ $root.$data.privateState.userInfo.name }} <a-icon type="down" /></span>
                <a-menu slot="overlay">
                  <a-menu-item @click="editUserInfoClick">
                    修改个人信息
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
              <a-icon class="logout-btn" type="logout" style="font-size: 20px;" @click="logoutConfirm"/>
            </div>
          </div>
        </div>
      </a-layout-header>
      <a-layout>
        <div style="padding:10px;">
          <a-tag
            v-for="it of $root.$data.views"
            :key="it.id"
            closable
            :class="$root.$data.selectedKeys.includes(it.menuKey) ? 'a-tag a-tag-active' : 'a-tag'"
            :afterClose="() => closeView(it)"
          >
              <span @click="clickView(it)">{{ it.name }}</span>
            </a-tag>
        </div>
        <a-layout-content :style="{ padding: '10px', minHeight: '280px' }">
          <keep-alive :include="$root.$data.viewsAlive">
          <router-view></router-view>
          </keep-alive>
        </a-layout-content>
      </a-layout>
    </a-layout>
    <a-drawer
      title="编辑个人信息"
      placement="right"
      :closable="false"
      @close="() => visibleEditUserInfo = false"
      :visible="visibleEditUserInfo"
    >
      <a-form :form="editUserForm">
        <a-form-item label="密码">
          <a-input
            v-decorator="[
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: '请输入密码',
                  },
                  {
                    validator: validateToNextPassword,
                  },
                ],
              },
            ]"
            type="password"
          />
        </a-form-item>
        <a-form-item label="确认密码">
          <a-input
            v-decorator="[
              'confirm',
              {
                rules: [
                  {
                    required: true,
                    message: '请确认密码',
                  },
                  {
                    validator: compareToFirstPassword,
                  },
                ],
              },
            ]"
            type="password"
            @blur="handleConfirmBlur"
          />
        </a-form-item>
      </a-form>
      <div style="padding-top:30px;">
        <a-button type="primary" @click="editUserInfoOk">提交</a-button>
      </div>
    </a-drawer>
  </a-layout>
</template>
<script>
import licia from 'licia'
import { Account } from '@/api'

export default {
  beforeCreate () {
    this.editUserForm = this.$form.createForm(this)
  },
  data () {
    return {
      collapsed: false,
      visibleEditUserInfo: false,
      confirmDirty: false,
    }
  },
  methods: {
    logoutConfirm () {
      const self = this
      this.$confirm({
        title: '确定要退出系统？',
        okText: '是',
        okType: 'danger',
        cancelText: '否',
        onOk() {
          self.$root.$data.views = []
          localStorage.removeItem('token')
          self.$router.replace({ name: 'Login' })
        }
      })
    },
    editUserInfoClick () {
      this.visibleEditUserInfo = true
    },
    async editUserInfoOk () {
      this.editUserForm.validateFieldsAndScroll(async (err, values) => {
        if (!err) {
          const res = await this.$axios.put(`${Account}/EditSelf`, { password: values.password })
            .catch(e => {
              return {
                status: false,
                message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
              }
            })
          if (!res.status) {
            this.$message.error(res.message)
            return
          }
          this.$message.success('修改个人信息成功')
          this.editUserForm.resetFields()
        }
        this.visibleEditUserInfo = false
      });
    },
    changeSiderCollapsed () {
      this.collapsed = this.$refs.sider.changeCollapsed()
    },
    clickView (view) {
      this.$router.push({ name: view.id })
        .then(() => {
          if (!licia.isEmpty(view.subMenuKey)) {
            for (const key of view.subMenuKey) {
              if (this.$root.$data.openKeys.includes(key)) {
                continue
              }
              this.$root.$data.openKeys.push(key)
            }
          }
          this.$root.$data.selectedKeys = [view.menuKey]
        })
        .catch(err => err)
    },
    closeView (view) {
      let delIndex = -1
      for (const i in this.$root.$data.views) {
        if (this.$root.$data.views[i].id === view.id) {
          delIndex = i
          break
        }
      }
      if (delIndex > -1) {
        this.$root.$data.views.splice(delIndex, 1)
      }
      if (this.$root.$data.viewsAlive.indexOf(view.id) > -1) {
        this.$root.$data.viewsAlive.splice(this.$root.$data.viewsAlive.indexOf(view.id), 1)
      }
      let pushParams = null
      let toView = null
      if (this.$root.$data.views.length === 0) {
        pushParams = { name: 'Dashboard' }
        toView = { subMenuKey: [], menuKey: 'Dashboard' }
      } else {
        pushParams = { name: this.$root.$data.views[this.$root.$data.views.length - 1].id }
        toView = this.$root.$data.views[this.$root.$data.views.length - 1]
      }
      if (pushParams !== null && toView !== null) {
        this.$router.push(pushParams)
          .then(() => {
            if (!licia.isEmpty(toView.subMenuKey)) {
              for (const key of toView.subMenuKey) {
                if (!this.$root.$data.openKeys.includes(key)) {
                  continue
                }
                this.$root.$data.openKeys.splice(this.$root.$data.openKeys.indexOf(key), 1)
              }
            } else {
              this.$root.$data.openKeys = []
            }
            this.$root.$data.selectedKeys = [toView.menuKey]
          })
          .catch(err => err)
      }
    },
    reloadView () {
      if (this.$root.$data.viewsShow !== null) {
        this.$root.$data.viewsShow.reload()
      }
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.editUserForm;
      if (value && value !== form.getFieldValue('password')) {
        callback('两次输入的密码不一致!');
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.editUserForm;
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], { force: true });
      }
      callback();
    },
  }
}
</script>
<style>
#main-layout {
  min-height: 100%;
}
.a-tag {
  padding: 8px;
  font-size: 13px;
  height: auto;
}
.a-tag-active {
  color: #2d8cf0;
}
.header {
  background: #fff;
  padding: 0;
  box-shadow: 0 2px 8px #f0f1f2;
  position: relative;
  z-index: 10;
  max-width: 100%;
}
.main-head-layout-r {
  float: right;
  padding: 0 24px;
}
.main-head-layout-r .logout-btn:hover {
  color: #1890ff;
}
#main-layout .trigger {
  font-size: 20px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

#main-layout .trigger:hover {
  color: #1890ff;
}

#main-layout .logo {
    height: 32px;
    margin: 16px;
    color: #fff;
    font-size: 16px;
    text-align: center;
}
</style>